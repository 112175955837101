<template>
  <v-card-text
    id="report"
    v-if="association && Object.keys(association).length > 0"
  >
    <div id="report_pdf">
      <Report_header type="All Collections" />
      <Top_association :association="association" />
      <Report_case :association="association" :payment-amount="paymentAmount" />
    </div>
    <Report_data_table :payments="association.payments" />
  </v-card-text>
  <Report_destructor v-else />
</template>

<script>
import { mapGetters } from "vuex";
import { GetOnlyOpened, processMoney } from "@/functions";
export default {
  name: "open_collections",
  components: {
    Report_destructor: () => import("@/components/report/report_destructor"),
    Report_data_table: () => import("@/components/report/report_data_table"),
    Report_case: () => import("@/components/report/report_case"),
    Top_association: () => import("@/components/report/top_association"),
    Report_header: () => import("@/components/report/report_header")
  },
  computed: {
    ...mapGetters({
      report: "report"
    }),
    association() {
      return GetOnlyOpened(this.reports)[this.report.index];
    },
    paymentAmount() {
      return Math.round(
        this.association.payments.reduce(
          (a, b) => a + processMoney(b["Payment Amount"]),
          0
        )
      );
    }
  },
  props: {
    reports: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style scoped></style>
